import React, { Component } from 'react';
import '../App.css';
import line from '../assets/roadmap-line2.png';
import shark from '../assets/HappyShark.png';
import rm from '../assets/Roadmap.png';
import box from '../assets/box-3.png';


class Roadmap extends Component {


    render() {
        return (

            <div class="boxWrap5">

                <div class="storyHRmM"><img src={rm} /></div>

                <div class="rmMain">
                    <div class="storyRm"><img src={rm} /></div>

                    <div class="rmP2"><img class="rmParrot" src={shark} />
                        <img class="line" src={line} />
                        <img class="box" src={box} /></div>

                    <div class="rmboxes">
                        <div class="rmBoxMain">
                            <div class="rmBox" data-aos="fade-down" data-aos-duration="1500">
                                <p><span class="year">PHASE 1: (NOV 22 – JAN 2023)</span></p>
                                <p>Bored Sharks Club Announcement</p>
                                <p>Release Business Plan</p>
                                <p>Twitter/Instagram Presence</p>
                                <p>Recruiting Sharks on Social Channels</p>
                                <p>Open Discord Channel for Sharks</p>
                                <p>Hold Twitter Spaces to Connect with Sharks</p>
                                <p>Mint Day: NOV 19th 2022</p>
                                <p>Giveaways (NFTs – ETH Prices)</p>
                            </div>

                            <div class="rmBox" data-aos="fade-down" data-aos-duration="1500">
                                <p><span class="year">PHASE 3: (APRIL 2023 – JULY/AUGUST 2023)</span></p>
                                <p>Announce Mint Day for 3D Collection</p>
                                <p>Hold 1st Bored Sharks Club IRL Conference</p>
                                <p>Hold 1st Bored Sharks Club Golf Tournament</p>
                                <p>Promote 3D Collection on social media</p>
                                <p>Hold Weekly Twitter Spaces</p>
                                <p>Airdrop 7 NFTs to each holder of 01 NFT from phase 1</p>
                                <p>Launch 3D Collection</p>

                            </div>

                        </div>

                        <div class="rmP"><img class="rmParrot" src={shark} />
                            <img class="line" src={line} />
                            <img class="box" src={box} /></div>

                        <div class="rmBoxMain2">
                            <div class="rmBox" data-aos="fade-up" data-aos-duration="1500">
                                <p><span class="year">PHASE 2: (JAN 2023 – MARCH 2023)</span></p>
                                <p>Launch Podcast and invite Holders to Participate</p>
                                <p>Organize the Bored Sharks Club Conference (Location TBD by Holders)</p>
                                <p>Organize the Bored Sharks Club Golf Tournament (Location TBD by Holders)</p>
                                <p>Commence Production of 3D Collection of 7777 items</p>
                                <p>Collaborations with Subject Matter Experts</p>
                                <p>Fine Tune Social Media Presence / Hire Discord Channel Moderators</p>
                                <p>Promote Phase 3</p>
                                <p>Giveaways (NFTs – ETH Prices)</p>
                            </div>

                            <div class="rmBox" data-aos="fade-up" data-aos-duration="1500">
                                <p><span class="year">PHASE 4: (SEPT 2023 – DECEMBER 2023)</span></p>
                                <p>TO BE ANNOUNCED</p>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


        );

    }
}

export default Roadmap;
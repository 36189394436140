import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import ourStory from '../assets/Our-Story.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story2 extends Component {

    render() {
        return (

            <div class="boxWrap3">

                <div class="storyH"><img src={ourStory} /></div>

                <div class="about2">
                    <div class="storyCon-2">
                        <p class="storyCon2">The Bored Sharks Club NFT Project was born from the need that most workers have regarding their own career development and progression. There are many so-called “Coach Gurus” out there that claim to be capable of helping anyone that chooses them as their mentors. However, these “Gurus” are only willing to provide their services for no less than US$5,000 on average.</p>
                        <p class="storyCon2">This makes it almost impossible for the average worker to find someone that can help them grow. The founder of The Bored Sharks Club will change that, by leveraging all the industry specific connections made after his 26-years career in the Energy Sector and bringing some of the most reputable SME’s to interact with the members of the Bored Sharks Club.</p>
                        <p class="storyCon2">However, it is important to acknowledge that developing a network of such magnitude will require effort and investment. Because of this reason, entry to the Bored Sharks Club will not be a free mint. It will require a nominal entry fee (Mint Price) of 0.4 ETH per Shark. 0.4 ETH is approximately US$520.00, which is 1/10 of the price that the average career guru charges their clients for their services, often with no results being obtained by the customers.</p>
                        <p class="storyCon2">Get ready to navigate the Ocean Waters and Mingle with Sharks from all over the world. We have plenty to learn from each other, and together we will grow like never before. PS: This will be just the beginning of a long journey together.</p>
                    </div>
                </div>


            </div>
        )
    }
}

export default Story2;


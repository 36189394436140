import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import shark from '../assets/HappyShark.png';
import intro from '../assets/Introduction.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2">

                <div class="storyH"><img src={intro}/></div>

                <div class="about">
                    <div class="storyCon">
                        <p class="storyCon2">The Bored Sharks Club is an upcoming NFT project made up of 777 unique Hand drawn and randomly generated NFTs for the Bored Sharks Club community members.</p>
                        <p class="storyCon2">But The Bored Sharks Club NFT Project is not the average NFT Collection out there. First, this project is not intended for Flippers or Whales, and this will not be a pump and dump exercise either. If you think you fit in these two categories, do yourself and us a favor and go find the next Free Mint out there.
                            With that said, The Bored Sharks Club is going to build what no other NFT project, or even a large corporation has done before to benefit the Hard-Working Professionals out there.
                        </p>
                        <p class="storyCon2">The founder (Giorgio Pasqualin) intent is to provide the most value to the future members of the Bored Sharks Club, and unlike most projects out there, the founder has a very well-defined answer for what “VALUE” means.</p>
                        <p class="storyCon2">In short words, holders will equally benefit from holding a minimum of just One Shark, which is going to be the entry card to this globally inclusive club.
                            From holding one shark, you will get access to IRL (In Real Life) Conferences in which many subject matter experts from multiple areas and disciplines will share their knowledge with the community members. Also, every holder will have the chance to participate as a speaker in an upcoming Podcast and present his/her expertise, or even business ideas with the network. Furthermore, every Shark you hold will give you access to a suite of online training related to Career Development, Leadership Skills and More.
                        </p>
                        <p class="storyCon2">While other NFT Projects out there just talk about value, We WILL DELIVER IT TO YOU.</p>
                    </div>
                    <img class="shark" src={shark} />

                </div>

            </div>
        )
    }
}

export default Story;

